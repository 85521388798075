<template>
  <div class="columns is-mobile is-flex is-gapless" ref="boardRef">
    <Category v-for="cat in categories"
      :title="cat.title"
      :key="cat.title"
      >
      </Category>
  </div>
</template>

<script>
import Category from "../components/Category";

export default {
  name: "Board",
  props: {
  },
  components: {
    Category,
  },
  data() {
    return {
      categories: [
        {title: "THE REAR END"},
        {title: "OH GOD, 'Y'"},
        {title: "SOGGY BOTTOM BOYS"},
        {title: "HAVE A GOOD TIME TONIGHT"},
        {title: "BILLYS"},
        {title: "PIANO MEN"},
      ]
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.columns {
  height: 60vh;
}
</style>
