<template>
<div>
    <div v-if="role == 'player'">
      <button class="button is-danger" :disabled="!(buzzersUnlocked)" @click="buzzIn()">BUZZ IN</button>
    </div>
    <div v-if="role == 'host'">
        <p class="heading" v-if="currentClue == ''">click on a clue to select it</p>
      <button class="button is-danger" v-if="currentPlayer == '' && currentClue != ''" @click="unlockBuzzers()">Unlock Buzzers</button>
      <nav class="level" v-if="currentPlayer != '' && currentClue != ''">
          <button class="level-item has-text-centered button is-success" @click="rightAnswer()">
            <span class="icon">
            <i class="fas fa-check fa-lg"></i>
            </span>
          </button>
          <button class="level-item has-text-centered button is-danger" @click="wrongAnswer()">
            <span class="icon">
            <i class="fas fa-times fa-lg"></i>
            </span>
          </button>
      </nav>
    </div>
</div>
</template>

<script>
export default {
  name: "Buzzer",
  props: {
    host: String,
  },
  data() {
    return {
      didBuzzLoop: null,
    }
  },
  methods: {
    buzzIn() {
      let buzzTime = Date.now() - this.$store.state.buzzerUnlockTime
      this.$store.dispatch("sendEventToHost", {
        event: "buzzIn",
        data: {"playerID": this.$store.state.playerID, "buzzTime": buzzTime}
      });
      this.$store.dispatch("setBuzzersUnlocked", false);
    },
    unlockBuzzers() {
      this.$store.dispatch("setBuzzersUnlocked", true);
      this.$store.dispatch("sendEventToClients", {
        event: "setBuzzer",
        data: {"unlocked": true}
      });

      this.$store.dispatch("resetWhoBuzzed")

      let checkID = 1;
      this.didBuzzLoop = setInterval(() => {
        this.$store.dispatch("sendEventToClients", {
          event: "didBuzz",
          data: {"checkID": checkID}
        });
        checkID++; // these come out of order, maybe just have incrementing watcher
      }, 200)
    },
    rightAnswer() {
      console.log("right answer")
    },
    wrongAnswer() {
      console.log("wrong answer")
    }
  },
  computed: {
    role() { return this.$store.state.role },
    currentPlayer() { return this.$store.state.currentPlayer },
    currentClue() { return this.$store.state.currentClue },
    buzzersUnlocked() { return this.$store.state.buzzersUnlocked },
    whoBuzzed() { return this.$store.state.whoBuzzed },
    forceUpdateToggler() { return this.$store.state.forceUpdateToggler },
  },
  watch: {
    currentPlayer: function() {
      if (this.$store.state.currentPlayer != "") {
        clearInterval(this.didBuzzLoop)
        console.log("CURRENT PLAYER", this.$store.state.currentPlayer)
      }
    },
    forceUpdateToggler: function() {
      if (this.$store.state.role != "host") { return; }

      let whoBuzzed = this.$store.state.whoBuzzed
      let buzzedPlayers = []
      for (let player in whoBuzzed) {
        console.log(whoBuzzed[player].buzzTime)
        if (whoBuzzed[player].buzzTime > 0) {
          buzzedPlayers.push(player)
        }
      }
      if (buzzedPlayers.length > 0) {
        let fastestPlayer = buzzedPlayers[0];
        for (let player in buzzedPlayers) { if (player['buzzTime'] > fastestPlayer['buzzTime']) {fastestPlayer = player} }
        let lowerCountPlayers = []
        for (let player in whoBuzzed) { if (player['checkID'] < fastestPlayer['checkID']) {lowerCountPlayers.push(player)} }
        if (lowerCountPlayers.length == 0) {
          this.$store.dispatch("setCurrentPlayer", fastestPlayer)
          this.$store.dispatch("setBuzzersUnlocked", false);
          this.$store.dispatch("sendEventToClients", {
            event: "setBuzzer",
            data: {"unlocked": false}
          });
          this.$store.dispatch("sendEventToClients", {
            event: "setCurrentPlayer",
            data: {"playerID": fastestPlayer}
          });
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
  padding-left: 25%;
  padding-right: 25%;
  height: 5vh;
  font-size: 3vmin;
}
</style>
