<template>
  <div class="column is-flex-direction-vertical">
    <Clue :titleText="title"> </Clue>
    <Clue v-for="clue in clues"
        :value="clue.value"
        :key="title + clue.value"
        :clueID="title + clue.value"
        :clueText="clue.clueText"
        >
    </Clue>
  </div>
</template>

<script>
import Clue from "../components/Clue";

export default {
  name: "Category",
  props: {
    title: String,
  },
  components: {
    Clue,
  },
  data() {
    return {
      clues: [
        {value: 200, clueText: "butts are butts"},
        {value: 400, clueText: "This biblical woman drove a tent peg through some guys head"},
        {value: 600, clueText: "meeps can bleep"},
        {value: 800, clueText: "feet can teef"},
        {value: 1000, clueText: "lobs are sters"}
      ],
    }
  },
};
</script>

<style scoped>
</style>
