<template>
  <div>
    <h1 class="title">Welcome to J-Party!</h1>
    
    <div v-if="!role" class="hostOrJoin">
      <div class="columns is-mobile">
        <div class="column is-6">
          <button class="button is-primary" @click="setRole('host')">Host Game</button>
        </div>
        <div class="column is-6">
          <button class="button is-info" @click="setRole('player')">Join Game</button>
        </div>
      </div>
    </div>
    
    <div v-if="role != ''">
      <GameLobby></GameLobby>
    </div>
  </div>
</template>

<script>
import GameLobby from "../login/GameLobby";

export default {
  name: "Login",
  props: {
    host: String,
  },
  data() {
    return {
      playerName: "",
    };
  },
  methods: {
    async setRole(s) {
      await this.$store.dispatch("setRole", s)
    }
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
  },
  components: {
    GameLobby,
  },
};
</script>

<style scoped>
.bottomBar {
  height: 30vh;
  width: 100%;
  bottom: 0;
  position: absolute; 
  padding-bottom: 2vh;
}
</style>
