<template>
<div>
  <div v-if="role == 'player' && roomConnection === null">
    <section class="section">
      <div class="field">
      <h2 class="title is-5">Enter Room Code</h2>
      <div class="control is-loading">
        <input class="input" v-model="roomID" type="text" placeholder="For example: 123345-asdfqw-1234">
      </div>
      <div class="control">
        <input class="input" v-model="playerName" type="text" placeholder="Your Name">
      </div>
      <button class="button is-primary" @click="joinRoom(roomID, playerName)">Join Room!</button>
      </div>
    </section>
  </div>
  <div v-else>
    <h1 class="subtitle is-4"><b>Room ID:</b> {{roomConnection.id ? roomConnection.id : roomConnection.peer }}</h1>
    <div class="columns is-centered is-mobile">
      <!-- <div v-for="player in clientConnections" class="column is-one-quarter"> -->
        <!-- I think I should keep a list of the players in the room with all my own metadata, and use the host's peer.connections to send to all the clients. -->
        <!-- That way it can be shared across client + server easily, so everyone can have the other player's states, but not connections. -->
        <!-- The host can also be responsible for making sure everyone is connected, in case of disconnects it can persist the players info/metadata -->
        <!-- NOTE: Key is player name as it will be unique -->
        <Player
          v-for="player in playerList"
          :key="player.name"
          :name="player.name"
          :score="player.score"
          class="column is-one-quarter"
        >
         </Player>
    </div>
    <button v-if="role == 'host'" class="button is-primary" @click="startGame()">Everyone's In!</button>
  </div>
</div>
</template>

<script>
import Player from "../Player"


export default {
  name: "GameLobby",
  props: {
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    roomConnection() {
      return this.$store.state.roomConnection;
    },
    playerList() {
      return this.$store.state.playerList;
    },
  },
  data() {
    return {
      remoteSigData: "",
      joinCode: "",
      remoteCode: "",
    };
  },
  methods: {
    addPlayer() {
      this.$store.dispatch('addClientConnection', this.remoteSigData)
    },
    joinRoom(roomID, playerName) {
      this.$store.dispatch("joinRoom", {
        roomID: roomID,
        playerName: playerName
      })  
    },
    startGame() {
      this.$store.dispatch("setView", "board");
      this.$store.dispatch("sendEventToClients", {
        event: "startGame",
        data: {}
      });
    }
  },
  components: {
    Player
  },
};
</script>

<style scoped>
</style>
