<template>
  <div class="playerItem">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      viewBox="175.11510791366908 268.2353193898661 256.0400281247906 216.994896437472"
      width="252.04"
      height="212.99"
    >
      <defs>
        <path
          d="M176.12 308.92L238.71 308.92L238.71 326.19L176.12 326.19L176.12 308.92Z"
          id="a5BrLMW4hk"
        ></path>
        <path
          d="M176.12 283.67L238.71 283.67L238.71 303.89L176.12 303.89L176.12 283.67Z"
          id="d20EmgcEM"
        ></path>
        <path
          d="M176.12 326.19L238.71 326.19L238.71 331.22L176.12 331.22L176.12 326.19Z"
          id="a14EyNdg4k"
        ></path>
        <path
          d="M176.12 303.89L238.71 303.89L238.71 308.92L176.12 308.92L176.12 303.89Z"
          id="c1cNIzClC"
        ></path>
        <path
          d="M365.57 283.67L428.16 283.67L428.16 303.89L365.57 303.89L365.57 283.67Z"
          id="f5wVdN5aAZ"
        ></path>
        <path
          d="M176.12 331.22L238.71 331.22L238.71 369.97L176.12 369.97L176.12 331.22Z"
          id="bODJ5IHd2"
        ></path>
        <path
          d="M365.57 308.92L428.16 308.92L428.16 326.19L365.57 326.19L365.57 308.92Z"
          id="cbg2jp9FI"
        ></path>
        <path
          d="M365.57 331.22L428.16 331.22L428.16 369.97L365.57 369.97L365.57 331.22Z"
          id="a7MenlE7z"
        ></path>
        <path
          d="M365.57 303.89L428.16 303.89L428.16 308.92L365.57 308.92L365.57 303.89Z"
          id="it2NRnjy5"
        ></path>
        <path
          d="M365.57 326.19L428.16 326.19L428.16 331.22L365.57 331.22L365.57 326.19Z"
          id="drCEDSgpD"
        ></path>
        <path
          d="M238.71 283.67L363.88 283.67L363.88 482.23L238.71 482.23L238.71 283.67Z"
          id="b1s4td1Hr"
        ></path>
        <path
          d="M240.79 285.4L361.65 285.4L361.65 320L240.79 320L240.79 285.4Z"
          id="auefTCwh1"
        ></path>
        <path
          d="M240.79 357.27L361.65 357.27L361.65 435.76L240.79 435.76L240.79 357.27Z"
          id="aekqZ0osV"
        ></path>
        <path
          d="M238.71 269.24L252.58 269.24L252.58 280.27L238.71 280.27L238.71 269.24Z"
          id="bar0"
        ></path>
        <path
          d="M252.58 269.24L266.45 269.24L266.45 280.27L252.58 280.27L252.58 269.24Z"
          id="bar1"
        ></path>
        <path
          d="M266.45 269.24L280.33 269.24L280.33 280.27L266.45 280.27L266.45 269.24Z"
          id="bar2"
        ></path>
        <path
          d="M280.33 269.24L294.2 269.24L294.2 280.27L280.33 280.27L280.33 269.24Z"
          id="bar3"
        ></path>
        <path
          d="M294.2 269.24L308.08 269.24L308.08 280.27L294.2 280.27L294.2 269.24Z"
          id="bar4"
        ></path>
        <path
          d="M308.08 269.24L321.95 269.24L321.95 280.27L308.08 280.27L308.08 269.24Z"
          id="bar5"
        ></path>
        <path
          d="M321.95 269.24L335.83 269.24L335.83 280.27L321.95 280.27L321.95 269.24Z"
          id="bar6"
        ></path>
        <path
          d="M335.83 269.24L349.7 269.24L349.7 280.27L335.83 280.27L335.83 269.24Z"
          id="bar7"
        ></path>
        <path
          d="M349.7 269.24L363.58 269.24L363.58 280.27L349.7 280.27L349.7 269.24Z"
          id="bar8"
        ></path>
        <text
          :id="scoreid"
          x="261.16"
          y="306.4"
          font-size="25"
          font-family="Roboto Slab"
          font-weight="normal"
          font-style="normal"
          :fill="scoreColor"
          letter-spacing="0"
          alignment-baseline="before-edge"
          transform="matrix(1 0 0 1 -17.282625243688727 -22.734809842657114)"
          style="line-height: 100%"
          xml:space="preserve"
          dominant-baseline="text-before-edge"
        >
          <tspan
            x="318"
            dy="0em"
            alignment-baseline="before-edge"
            dominant-baseline="text-before-edge"
            text-anchor="middle"
          >
            {{ scoreString }}
          </tspan>
        </text>
        <text
          :id="nameid"
          x="261.16"
          y="306.4"
          font-size="30"
          font-family="Damion"
          font-weight="normal"
          font-style="normal"
          fill="white"
          letter-spacing="0"
          alignment-baseline="before-edge"
          transform="matrix(1 0 0 1 -18.972069006753344 72.61442705835424)"
          style="line-height: 100%"
          xml:space="preserve"
          dominant-baseline="text-before-edge"
        >
          <tspan
            x="318"
            dy="0em"
            alignment-baseline="before-edge"
            dominant-baseline="text-before-edge"
            text-anchor="middle"
          >
            {{ name }}
          </tspan>
        </text>
      </defs>
      <g>
        <g>
          <use
            xlink:href="#a5BrLMW4hk"
            opacity="1"
            fill="#9b5543"
            fill-opacity="1"
          ></use>
        </g>
        <g>
          <use
            xlink:href="#d20EmgcEM"
            opacity="1"
            fill="#9b5543"
            fill-opacity="1"
          ></use>
        </g>
        <g>
          <use
            xlink:href="#a14EyNdg4k"
            opacity="1"
            fill="#fdf6a9"
            fill-opacity="1"
          ></use>
        </g>
        <g>
          <use
            xlink:href="#c1cNIzClC"
            opacity="1"
            fill="#fdf6a9"
            fill-opacity="1"
          ></use>
        </g>
        <g>
          <use
            xlink:href="#f5wVdN5aAZ"
            opacity="1"
            fill="#9b5543"
            fill-opacity="1"
          ></use>
        </g>
        <g>
          <use
            xlink:href="#bODJ5IHd2"
            opacity="1"
            fill="#9b5543"
            fill-opacity="1"
          ></use>
        </g>
        <g>
          <use
            xlink:href="#cbg2jp9FI"
            opacity="1"
            fill="#9b5543"
            fill-opacity="1"
          ></use>
        </g>
        <g>
          <use
            xlink:href="#a7MenlE7z"
            opacity="1"
            fill="#9b5543"
            fill-opacity="1"
          ></use>
        </g>
        <g>
          <use
            xlink:href="#it2NRnjy5"
            opacity="1"
            fill="#fdf6a9"
            fill-opacity="1"
          ></use>
        </g>
        <g>
          <use
            xlink:href="#drCEDSgpD"
            opacity="1"
            fill="#fdf6a9"
            fill-opacity="1"
          ></use>
        </g>
        <g>
          <use
            xlink:href="#b1s4td1Hr"
            opacity="1"
            fill="#0101ee"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#b1s4td1Hr"
              opacity="1"
              fill-opacity="0"
              stroke="#000000"
              stroke-width="4"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#auefTCwh1"
            opacity="1"
            fill="#0149ee"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#auefTCwh1"
              opacity="1"
              fill-opacity="0"
              stroke="#000000"
              stroke-width="0"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#aekqZ0osV"
            opacity="1"
            fill="#0149ee"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#aekqZ0osV"
              opacity="1"
              fill-opacity="0"
              stroke="#000000"
              stroke-width="0"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#bar0"
            opacity="1"
            :fill="this.lightBar[0]"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#bar0"
              opacity="1"
              fill-opacity="0"
              stroke="#ffffff"
              stroke-width="2"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#bar1"
            opacity="1"
            :fill="this.lightBar[1]"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#bar1"
              opacity="1"
              fill-opacity="0"
              stroke="#ffffff"
              stroke-width="2"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#bar2"
            opacity="1"
            :fill="this.lightBar[2]"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#bar2"
              opacity="1"
              fill-opacity="0"
              stroke="#ffffff"
              stroke-width="2"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#bar3"
            opacity="1"
            :fill="this.lightBar[3]"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#bar3"
              opacity="1"
              fill-opacity="0"
              stroke="#ffffff"
              stroke-width="2"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#bar4"
            opacity="1"
            :fill="this.lightBar[4]"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#bar4"
              opacity="1"
              fill-opacity="0"
              stroke="#ffffff"
              stroke-width="2"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#bar5"
            opacity="1"
            :fill="this.lightBar[5]"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#bar5"
              opacity="1"
              fill-opacity="0"
              stroke="#ffffff"
              stroke-width="2"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#bar6"
            opacity="1"
            :fill="this.lightBar[6]"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#bar6"
              opacity="1"
              fill-opacity="0"
              stroke="#ffffff"
              stroke-width="2"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#bar7"
            opacity="1"
            :fill="this.lightBar[7]"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#bar7"
              opacity="1"
              fill-opacity="0"
              stroke="#ffffff"
              stroke-width="2"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g>
          <use
            xlink:href="#bar8"
            opacity="1"
            :fill="this.lightBar[8]"
            fill-opacity="1"
          ></use>
          <g>
            <use
              xlink:href="#bar8"
              opacity="1"
              fill-opacity="0"
              stroke="#ffffff"
              stroke-width="2"
              stroke-opacity="1"
            ></use>
          </g>
        </g>
        <g id="a2R0Waw4t">
          <use
            :xlink:href="hnameid"
            opacity="1"
            fill="#1c00e9"
            fill-opacity="1"
          ></use>
        </g>
        <g id="a11s4rrU2W">
          <use
            :xlink:href="hscoreid"
            opacity="1"
            fill="#1c00e9"
            fill-opacity="1"
          ></use>
        </g>
      </g>
    </svg>
     <!-- <p :style="dynamicText">  {{ name }} </p> -->
  </div>
</template>

<script>
export default {
  name: "Player",
  props: {
    name: String,
    score: Number,
  },
  data() {
    return {
      hscoreid: "#" + this.score + "123",
      scoreid: this.score + "123",
      nameid: this.name + "123",
      hnameid: "#" + this.name + "123",
      lightBar: ["grey", "grey", "grey", "grey", "grey", "grey", "grey", "grey", "grey"]
    };
  },
  computed: {
    scoreColor () {
      if (this.score < 0) {
        return "red";
      } else {
        return "white";
      }
    },
    scoreString () {
      return this.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    currentPlayer() { return this.$store.state.currentPlayer },
  },
  watch: {
    currentPlayer: function() {
      if (this.$store.state.currentPlayer == this.name) {
        this.lightBar = Array(9).fill("red")
        let timerLoop = setInterval(() => {
          if (this.lightBar.every((v) => v == "grey")) {
            clearInterval(timerLoop);
            return;
          } else {
            for (let i=0; i < 5; i++) {
              if (!(this.lightBar[i] == "grey" && this.lightBar[8 - i] == "grey")) {
                this.lightBar.splice(i, 1, "grey")
                this.lightBar.splice(8 - i, 1, "grey")
                break;
              }
            }
          }
        }, 1200) // TODO: Configurable
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.playerItem {
  height: 100%;
  margin-left: -5px; 
  margin-right: -5px;
}
svg {
  height: 100%;
  width: 100%;
}
</style>
