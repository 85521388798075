<template>
  <nav class="level is-mobile">
    <div class="level-left">
      <p class="title">J-Party!</p>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">With Your Host:</p>
        <p class="title">{{ host }}</p>
      </div>
    </div>
    <div class="level-right">
      <p class="heading">Jeopardy Round</p>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Banner",
  props: {
    host: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.level {
  height: 5vh;
}
.heading {
  font-size: 1.5vmin;
}
.title {
  font-size: 4vmin;
}
</style>
