let network = {
    hostOnOpen: function(state, conn) {
        // TODO: Validate name doesn't exist in playerList, and is not empty
        // Validate also that playerlabel is the same for reconnection. I'll see how local storage works, might want to save own uid or something.
        state.clientConnections.push(conn);
        state.playerList.push({"name": conn.label, "score": 0})
        state.clientConnections.forEach(conn => {
            this.send(conn, "connectedToHost", {playerList: state.playerList});
        });
    },

    hostOnData: function(state, data) {
        switch(data.event) {
            case 'buzzIn':
                console.log("Player", data.data.playerID, "buzzed at", data.data.buzzTime, "ms")
                state.whoBuzzed[data.data.playerID].buzzTime = data.data.buzzTime
                state.forceUpdateToggler = !state.forceUpdateToggler
                break;
            case 'didBuzz':
                if (state.currentPlayer != '') {
                    state.whoBuzzed[data.data.playerID].checkID = data.data.checkID
                    state.forceUpdateToggler = !state.forceUpdateToggler
                }
                break;
            default:
              console.log("Recieved unhandled event", data.event)
        }
    },

    clientOnOpen: function(state, conn) {
        state.roomConnection = conn;
        console.log("connected!")
    },

    clientOnData: function(state, data) {
          switch(data.event) {
            case 'connectedToHost':
              state.playerList = data.data.playerList;
              break;
            case 'startGame':
              state.currentView = "board";
              break;
            case 'setClue':
                state.currentClue = data.data.clueID
                break;
            case 'setBuzzer':
                state.buzzersUnlocked = data.data.unlocked
                state.buzzerUnlockTime = Date.now()
                break;
            case 'setCurrentPlayer':
                state.currentPlayer = data.data.playerID
                break;
            case 'didBuzz':
                this.send(state.roomConnection, "didBuzz", {
                    "checkID": data.data.checkID,
                    "playerID": state.playerID,
                })
                break;
            default:
              console.log("Recieved unhandled event", data.event)
          }
    },

    send: function(conn, event, data) {
        /*
            At the end, wrap this with a 100ms local running timer, that pulls from a dict of messages with message IDs
            {
                182374: {event: "blah" data: {stuff} id: 182374}
                182322: {event: "blah2" data: {stuff} id: 182322}
            }
            Each one of these messages is sent in a 100ms interval repeatedly until the host recieves an ACK (event: ack, data: {id: 182374}),
            it then removes the event from the dict (and stops sending it)
            The whole games network is supposed to be sequential. Maybe the ID can just be sequential on the host, and each client needs to ACK that it got the
            last ID (shared by everyone) before the host sends the next thing.

            The buzzer click event can calculate the timediff between the gotten event and the current time, to the millisecond, and just the response with
            {timeClicked: "time-normalized-to-utc"} can be sent to the host. Once it gets an ack from everyone on the repeated buzzer event,
            it diffs the time difference from everyone. Should have a graphic for the clients showing that we're waiting on someone's connection maybe.
        */
        conn.send({event: event, data: data})
    }


}
export {network as default};