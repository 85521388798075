import Vue from "vue";
import Vuex from 'vuex';
import App from "./App.vue";
import "bulma/css/bulma.css";
import "fontsource-roboto-slab";
import "fontsource-damion";
import Peer from 'peerjs';
import network from "./network";

Vue.use(Vuex)
Vue.config.productionTip = false;

const store = new Vuex.Store({
  state: {
    playerID: "",
    score: 0,
    // role: "host",
    role: "",
    currentView: "login",
    currentClue: "",
    currentPlayer: "",
    buzzersUnlocked: false,
    buzzerUnlockTime: 0,
    whoBuzzed: {},
    forceUpdateToggler: false,
    peer: null,
    // roomConnection: {"id": "123"},
    roomConnection: null, 
    clientConnections: [],
    // playerList: [{name: "Slipf"}, {name: "Majorny"}],
    playerList: [],
  },
  mutations: {
    setView (state, viewName) {
      state.currentView = viewName
    },
    initConnection (state, connection) {
      state.connection = connection
    },
    setLastGenKey(state, key) {
      state.lastGenKey = key
    },
    setRole(state, role) {
      state.role = role
    },
    setPeer(state, peer) {
      state.peer = peer
    },
    setCurrentClue(state, clueID) {
      state.currentClue = clueID
    },
    setCurrentPlayer(state, player) {
      state.currentPlayer = player
    },
    setBuzzersUnlocked(state, isUnlocked) {
      state.buzzersUnlocked = isUnlocked
    },
    resetWhoBuzzed(state) {
      for (let player of state.playerList) {
        state.whoBuzzed[player.name] = {"checkID": 0, "buzzTime": -1};
      }
    },
    setHostConnection(state) {
      state.roomConnection = state.peer;
      state.peer.on('connection', function(conn) {

          conn.on('open', function() {
            network.hostOnOpen(state, conn)

            conn.on('data', function(data) {
              network.hostOnData(state, data)
            })
          })
      })
    },
    setClientConnection(state, payload) {
      var peerID = payload.roomID;
      var playerName = payload.playerName
      state.playerID = playerName
      var roomConnection = state.peer.connect(peerID, {label: playerName})
      
      roomConnection.on('open', function() {
        network.clientOnOpen(state, roomConnection);
        
        roomConnection.on('data', function(data) {
          network.clientOnData(state, data)
      });
    });
    }
  },
  actions: {
    setView (context, viewName) {  
      // viewNames: board, clue, answering, login
      context.commit('setView', viewName);
    },

    setCurrentClue(context, clueID) {
      context.commit('setCurrentClue', clueID)
    },
    
    setCurrentPlayer(context, player) {
      context.commit('setCurrentPlayer', player)
    },
    
    setBuzzersUnlocked(context, isUnlocked) {
      context.commit('setBuzzersUnlocked', isUnlocked);
    },

    resetWhoBuzzed(context) {
      context.commit('resetWhoBuzzed');
    },

    setRole (context, role) {
      context.commit('setRole', role)
      var peer = new Peer() // maybe this needs to be async?
      context.commit('setPeer', peer)
      if (role == "host") {
        context.commit('setHostConnection');
      }
    },

    joinRoom (context, payload) {
      context.commit('setClientConnection', payload)
    },

    sendEventToHost(context, payload) {
      network.send(context.state.roomConnection, payload.event, payload.data);
    },

    sendEventToClients(context, payload) {
      context.state.clientConnections.forEach(conn => {
        network.send(conn, payload.event, payload.data);
      });
    },
  }
})

new Vue({
  store: store,
  render: (h) => h(App),
}).$mount("#app");