<template>
  <div id="app">
    <div id="loginView" v-if="currentView == 'login'">
      <Login></Login>
    </div>
    <div v-else id="boardView">
      <div class="columns is-flex-direction-column is-mobile">
        <div class="column" id="bannerColumn">
          <Banner host="Ringo Trebek"> </Banner>
        </div>
        <div class="column" id="boardColumn">
          <Signal></Signal>
          <Board id="gameBoard"> </Board>
          <Signal></Signal>
        </div>
        <div class="column" id="contestantsColumn">
          <Contestants> </Contestants>
        </div>
        <div class="column" id="buzzerColumn">
          <Buzzer> </Buzzer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Board from "./components/Board";
import Contestants from "./components/Contestants";
import Banner from "./components/Banner";
import Buzzer from "./components/Buzzer";
import Signal from "./components/Signal";
import Login from "./components/login/Login"

export default {
  name: "App",
  components: {
    Board,
    Contestants,
    Banner,
    Buzzer,
    Signal,
    Login,
  },
  computed: {
    currentView() { return this.$store.state.currentView; }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: "#80c6f5";
  margin-top: 1vh;
  margin-bottom: 1vh;
}
::-webkit-scrollbar {
  display: none;
}
#boardColumn {
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}

#contestantsColumn {
  padding-top: 0;
  padding-bottom: 0;
}

#buzzerColumn {
  padding-top: 0.5vmin;
  padding-bottom: 0.5vmin;
}

html {
  background-color: #80c6f5;
}
</style>
