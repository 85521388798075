<template>
  <div class="contestantBox">
    <Player
      v-for="player in playerList"
      :key="player.name"
      :name="player.name"
      :score="player.score"
    >
    </Player>
  </div>
</template>

<script>
import Player from "../components/Player";

export default {
  name: "Contestants",
  props: {
    msg: String,
  },
  components: {
    Player,
  },
  computed: {
    playerList() { return this.$store.state.playerList; }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contestantBox {
  height: 23vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}
</style>
