<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118 574">
    <g stroke="#000" stroke-miterlimit="10" data-name="Layer 2">
      <path fill="#147acc" stroke-width="7" d="M3.5 3.5h111v567H3.5z" />
      <path :fill="signalColor" stroke-width="15" d="M30 26h59v520H30z" />
      <path
        fill="none"
        stroke-width="6"
        d="M35 91.86h50M33 26h50M35 157.71h50M35 223.57h50M35 289.43h50M35 355.29h50M35 421.14h50M35 487h50"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Signal",
  props: {
    on: Boolean,
  },
  computed: {
    signalColor() {
        if (this.$store.state.buzzersUnlocked) { return "white" }
        else { return "#b98f66" }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
svg {
  height: 50vh;
  width: 10vw;
}
</style>
