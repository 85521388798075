<template>
  <div ref="clueContainer" :class="{selected: isSelected}" @click="showClue()" :style="computedContainer">
    <div class="clueBox">
      <p v-if="titleText" class="titleText">{{ titleText }}</p>
      <p v-else-if="isSelected" class="clueText">{{ clueText }}</p>
      <p v-else-if="!isUsed" class="valueText">${{ value }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Clue",
  props: {
    value: Number,
    titleText: String,
    clueText: String,
    clueID: String,
  },
  data() {
    return {
      boardBoundingRect: "",
      clueBoundingRect: "",
      isSelected: false,
      isUsed: false
    }
  },
  methods: {
    showClue() {
      if (this.titleText) { return; }
      if (this.isUsed) { return; }
      if (this.$store.state.role != "host") { return; }
      this.$store.dispatch("sendEventToClients", {
        event: "setClue",
        data: {"clueID": this.clueID}
      });
      this.$store.dispatch("setCurrentClue", this.clueID);
    },
    startClueAnimation() {
      let board = document.getElementById("gameBoard")
      this.boardBoundingRect = board.getBoundingClientRect()
      this.clueBoundingRect = this.$refs.clueContainer.getBoundingClientRect()
      this.isSelected = true
    }
  },
  computed: {
    computedContainer() {
      let columnNum = Math.floor(((this.clueBoundingRect.x - this.boardBoundingRect.x ) / ((this.boardBoundingRect.width) / 6) + 0.2 ))
      let rowNum = Math.floor(((this.clueBoundingRect.y - this.boardBoundingRect.y ) / (this.boardBoundingRect.height / 6) + 0.2 ))
      let xOffset = "-" + columnNum * 16.6 + "%";
      let yOffset = "-" + rowNum * 16.6 + "%"; 
      return {
        "width": "100%",
        "height": "16.6%",
        "transform-origin": "top left",
        "--boardX": this.boardBoundingRect.x,
        "--boardY": this.boardBoundingRect.y,
        "--transformX":  xOffset,
        "--transformY": yOffset
      }
    },
    currentClue() { return this.$store.state.currentClue; },
  },
  watch: {
    currentClue: function() {
      if (this.$store.state.currentClue == this.clueID) {
        this.startClueAnimation();
      }
      if (this.$store.state.currentClue == "" && this.isSelected) {
        this.isSelected = false;
        this.isUsed = true;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clueBox {
  width: 100%;
  height: 100%;
  background-color: #060CE9;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.valueText {
  color: gold;
  font-family: "Roboto Slab";
  font-weight: bolder;
  font-size: 4.5vmin;
}
.titleText {
  color: white;
  font-family: "Roboto Slab";
  font-weight: bolder;
  font-size: 2vmin;
  margin-top: 5%;
  margin-bottom: 5%;
}
.clueText {
  color: white;
  font-family: "Roboto Slab";
  font-weight: bolder;
  font-size: 1vmin;
  margin-top: 5%;
  margin-bottom: 5%;
}
.selected {
  animation: showClueAnimation 1s forwards;
}
@keyframes showClueAnimation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(6) translate(var(--transformX), var(--transformY));
  }
}
</style>
